import styled from "styled-components";

export const ProductsContainer = styled.div`
    margin-top: 5%;
    margin-left: 15%;
    width: 90%;
    display:flex;
    @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const ProductsContainerCards = styled.div`
    margin-top: 5%;
    margin-left: 1%;
    width: 30%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,0.03);
    box-shadow: 1px 1px 15px rgba(0,0,0,0.5);
    margin-right: 14%;
    @media (max-width: 600px) {
        width:75%;
        margin-top: 10%;
        margin-bottom: 10%;
  }
`

export const ProductsContainerCardsImage = styled.img`
    margin-top: 5%;
    width: 80%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
`

export const ProductsContainerCardsButton = styled.button`
    width: 45%;
    background-color: green;
    color: white;
    border-radius: 4px;
    border: 1px white solid;
    padding:5px;
    margin-bottom:10%;
    font-weight: 700;
    &:hover{
        cursor: pointer;
        opacity: 0.7;
    }
    @media (max-width: 600px) {
        width:75%;
        height:40px;
    }
`