import React, { useState } from 'react';
import { ProductsContainer, ProductsContainerCards, ProductsContainerCardsImage, ProductsContainerCardsButton } from './styles.ts';
import GreenDifusor from '../../images/difusor-roxo.jpeg';

export const ProductsComponent: React.FC = () => {

  return (
    <>
    <h1 style={{marginBottom:'-5%', marginTop:'3%', borderBottom:'1px black solid', padding:'15px', color:'rgba(0,0,0,0.5)', marginLeft:'15%', marginRight:'15%'}}>Nossos Produtos mais recentes</h1>

    <ProductsContainer>
        <ProductsContainerCards>
        <div>
            <ProductsContainerCardsImage alt="difusor roxo" src={GreenDifusor}/>
            <h3>Difusor de Ambiente (Lavanda)</h3>
            <p style={{width:'60%', marginLeft:'20%'}}>Difusor de ambiente de lavanda, entre em contato bla bla bla</p>
            <div style={{display:'flex', marginLeft:'30%', marginTop:'-3%'}}>
                <p style={{marginRight:'6%', fontWeight:'700', color:'red'}}><s>R$ 199.00</s></p>
                <p style={{ fontWeight:'700', color:'green'}}>R$ 119.00</p>
            </div>
            <ProductsContainerCardsButton>
            <a style={{color:'white', textDecoration:'none'}} href={`https://api.whatsapp.com/send/?phone=5511971938148&text=Olá, estou interessado em comprar um difusor de ambiente roxo&type=phone_number&app_absent=0`} target="_blank">
                Entrar em contato
            </a>
            </ProductsContainerCardsButton>
        </div>
        </ProductsContainerCards>
        <ProductsContainerCards>
        <div>
            <ProductsContainerCardsImage alt="difusor roxo" src={GreenDifusor}/>
            <h3>Difusor de Ambiente (Lavanda)</h3>
            <p style={{width:'60%', marginLeft:'20%'}}>Difusor de ambiente de lavanda, entre em contato bla bla bla</p>
            <div style={{display:'flex', marginLeft:'30%', marginTop:'-3%'}}>
                <p style={{marginRight:'6%', fontWeight:'700', color:'red'}}><s>R$ 199.00</s></p>
                <p style={{ fontWeight:'700', color:'green'}}>R$ 119.00</p>
            </div>
            <ProductsContainerCardsButton>
            <a style={{color:'white', textDecoration:'none'}} href={`https://api.whatsapp.com/send/?phone=5511971938148&text=Olá, estou interessado em comprar um difusor de ambiente roxo&type=phone_number&app_absent=0`} target="_blank">
                Entrar em contato
            </a>
            </ProductsContainerCardsButton>
        </div>
        </ProductsContainerCards>
    </ProductsContainer>
    </>
  );
};

